// extracted by mini-css-extract-plugin
export var bannerShow = "banner-module--bannerShow--CbETb";
export var btn = "banner-module--btn--Wjf2g";
export var btnBlock = "banner-module--btnBlock--uB5LF";
export var container = "banner-module--container--ieSx2";
export var darkColor = "banner-module--darkColor--sDBSs";
export var hideMobBlock = "banner-module--hideMobBlock--ME8x+";
export var img = "banner-module--img--t9nC9";
export var mobBtn = "banner-module--mobBtn--dhi7D";
export var secondContainer = "banner-module--secondContainer--W0-+Z";
export var text = "banner-module--text--VWWYE";