import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/halvaMedia"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { NewFooter } from "../components/NewFooter"
import { FormPKW } from "../components/FormPKW"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank />
      <Banner darkColor orderNum="1" />
      <CardWithMaxBenefits orderNum="2" />
      <FormPKW
        emptyForm={false}
        dataLayerName="shortPersonalForm"
        formBtnText="Оформить карту"
        orderNum="3"
      />
      <NewFooter ligal={ligal} orderNum="4" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/halva-media/") {
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
